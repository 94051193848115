<template>
    <div class="my-account">
        <h1>Мой аккаунт</h1>
        <md-table :value="account">
            <md-table-row
                slot-scope="{ item }"
                slot="md-table-row"
            >
                <md-table-cell md-label="First Name">{{ item.firstName }}</md-table-cell>
                <md-table-cell md-label="Last Name">{{ item.lastName }}</md-table-cell>
                <md-table-cell md-label="Birthdate">{{ formatDate(item.birthDate) }}</md-table-cell>
                <md-table-cell md-label="Birthplace">{{ item.birthPlace }}</md-table-cell>
            </md-table-row>
        </md-table>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex"
export default {
    name: 'my-account',

    computed: {
        ...mapGetters('auth',['accountData', 'userData']),
        account() {
            return this.accountData ? [this.accountData] : []
        }
    },

    watch: {
        userData: {
            immediate: true,
            deep: true,
            handler(val) {
                if(!val) {
                    this.setAccountData(null)
                }
            },
        },
    },

    async mounted() {
       await this.getMyAccountData()
    },

    methods: {
        ...mapActions('auth', ['getMyAccountData']),
        ...mapMutations('auth', ['setAccountData']),
        formatDate(date) {
            return date ? this.$moment(date).format('YYYY-MM-DD') : '-'
        }
    },
}
</script>

<style lang="scss" scoped>
.my-account {
    width: 100%;
    h1 {
        margin: 35px 0 45px;
        font-weight: 900;
        font-size: 36px;
        line-height: 52px;
    }
}

</style>

<style lang="scss">
.my-account {
    .md-table {
        width: 100%;
        box-shadow: unset !important;
    }
    .md-table + .md-table {
        margin-top: 16px
    }
    .md-ripple, .md-table-cell-container {
        font-weight: normal;
        font-size: 18px;
        line-height: 32px;
        color: #000000;
        text-align: left;
    }
    .md-table-fixed-header-container > table > thead > tr {
        border-bottom: 1px solid #000000;;
    }
    .md-table-head-label {
        font-weight: bold;
    }
}
</style>